import * as culori from 'culori'

const Home = function() {

  let elements, colorSwitch

  function init() {
    elements = {
      stage: document.querySelector('.page-hero'),
    }

    if (elements.stage) {
      const backgroundColor = elements.stage.style.backgroundColor
      colorSwitch = culori.interpolate([backgroundColor, 'black'])
    }

    onScroll()
  }

  function onScroll() {
    if (elements.stage) {
      const fade = window.pageYOffset / (elements.stage.offsetHeight / 2)
      const bgColor = culori.formatter('rgb')(colorSwitch(fade))
      const lightness = culori.converter('hsl')(culori.parse(bgColor)).l

      Object.assign(elements.stage.style, {
        color: lightness >= .6 ? 'black' : 'white',
        backgroundColor: bgColor,
      })
    }
  }

  return {
    init,
    onScroll
  }
}()

export default Home
