import $ from 'jquery'
import * as culori from 'culori'
import { elementsFromPoint, offsetRect, IE11 } from './helpers'

const Header = function () {

  let elements,
    logoPosition = [0, 0],
    currentElement = null

  function init() {
    elements = {
      header: document.querySelector('.header'),
      logo: document.querySelector('.navbar-brand object'),
      contact: document.querySelector('.navbar-contact'),
    }

    if (elements.header) {
      $(document).on({
        'show.bs.modal': addHeaderClass,
        'hide.bs.modal': removeHeaderClass
      })
    }

    if (elements.contact) {
      const svg = elements.contact.querySelector('svg')

      if (!svg) return

      setTimeout(() => {
        const bbox = svg.getBBox()
        svg.setAttribute('width', bbox.width + 'px');
        svg.setAttribute('height', bbox.height + 'px');
        svg.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
      }, 500)
    }

    if (IE11) {
      setLogoFillByHeaderClassName()
    }

    onScroll()
    onResize()
  }

  function onResize() {
    if (elements.logo) {
      const logoOffset = offsetRect(elements.logo)

      logoPosition = [
        logoOffset.left,
        logoOffset.bottom,
      ]
    }
  }

  function onScroll() {
    if (elements.logo) {
      const element = elementsFromPoint(...logoPosition).find(elem => {
        if (elem.matches('[data-header-difference], img'))
          return true

        if (elem.matches('.modal-backdrop'))
          return false

        let background = window.getComputedStyle(elem).backgroundColor
        return background !== 'rgba(0, 0, 0, 0)' && background !== 'transparent'
      })

      const fromTop = element.getBoundingClientRect().top
      const headerHeight = elements.header.offsetHeight

      if (
        (element !== currentElement) ||
        (element.matches('[data-header-difference="start"]') && fromTop > -headerHeight) ||
        (element.matches('[data-header-difference="full"]')) ||
        (element.matches('[data-header-difference="end"]'))
      ) {
        currentElement = element

        /**
         * Make the logo "adaptive" to the background if `mix-blend-mode` is
         * supported and we are either:
         * - At the beginning of an `[data-header-difference="start"]` element
         * - Anywhere inside of an `[data-header-difference="full"]` element
         * - Or just below an `[data-header-difference="end"]` element
         */
        if (
          typeof CSS !== 'undefined' && CSS.supports('mix-blend-mode', 'difference') && (
            (element.matches('[data-header-difference="start"]') && fromTop > 0) ||
            (element.matches('[data-header-difference="full"]')) ||
            (element.matches('[data-header-difference="end"]') && (
              window.pageYOffset >= element.offsetTop + element.offsetHeight - (headerHeight / 2)
            ))
          )
        ) {
          elements.header.classList.remove('header-dark', 'header-light')
          elements.header.classList.add('header-difference')

          return
        }
        /*
         * Else, just make the logo not "adaptive" anymore
         */
        else {
          elements.header.classList.remove('header-difference')
        }

        /**
         * Stop here for images
         */
        if (element.matches('img')) {
          return
        }

        /**
         * Get the current background color by traversing up the tree until
         * an element that has a background color applied is found
         */
        let elem = element
        let background = window.getComputedStyle(elem).backgroundColor

        while (
          elem.parentElement &&
          (background === 'rgba(0, 0, 0, 0)' || background === 'transparent')
        ) {
          elem = elem.parentElement
          background = window.getComputedStyle(elem).backgroundColor
        }

        /**
         * Check for the lightness of the background color and make the header
         * either light or dark
         */
        const lightness = culori.converter('hsl')(culori.parse(background)).l
        if (lightness >= .6) {
          elements.header.classList.remove('header-dark')
          elements.header.classList.add('header-light')
        } else {
          elements.header.classList.remove('header-light')
          elements.header.classList.add('header-dark')
        }
      }
    }
  }

  function destroy() {
    if (elements.header) {
      $(document).off({
        'show.bs.modal': addHeaderClass,
        'hide.bs.modal': removeHeaderClass
      })
    }
  }

  function addHeaderClass(event) {
    if (event.target.id === 'menu') {
      elements.header.classList.add('is-open')
    }
  }

  function removeHeaderClass(event) {
    if (event.target.id === 'menu') {
      elements.header.classList.remove('is-open')
    }
  }

  function setLogoFillByHeaderClassName() {
    if (elements.header && elements.logo) {
      let loaded = false, contentDocument

      if (loaded) {
        setFill()
      } else {
        elements.logo.addEventListener('load', () => {
          loaded = true
          contentDocument = elements.logo.contentDocument

          setFill()

          const observer = new MutationObserver(mutations => {
            mutations.forEach(setFill)
          })

          observer.observe(elements.header, { attributes: true });
        })
      }

      function setFill() {
        if (
          (elements.header.classList.contains('header-light')) ||
          (elements.header.classList.contains('is-open'))
        ) {
          contentDocument.documentElement.style.fill = 'black'
        }

        else if (elements.header.classList.contains('header-dark')) {
          contentDocument.documentElement.style.fill = 'white'
        }
      }
    }
  }

  return {
    init,
    onScroll,
    onResize,
    destroy,
  }
}()

export default Header
