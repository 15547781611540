import ScrollTrigger from '@terwanerik/scrolltrigger'

import { offsetRect, randomIntFromInterval } from './helpers'

const Animations = function () {

  const selectors = {
    teaser: '.teaser',
    container: '.container',
    sections: ['#space', '#design', '#event', '#campaign'],
    logo: '.navbar-brand object',
    contact: '.navbar-contact',
    tagline: 'main object',
    svgGroups: ['#a', '#z', '#zz', '#u', '#n', '#i', '#q', '#uu'],
  }

  let elements

  function init() {
    const excluded = selectors.sections.map(s => `:not(${s})`).join('')

    elements = {
      logo: document.querySelector(selectors.logo),
      contact: document.querySelector(selectors.contact),
      containers: document.querySelectorAll(excluded + ' > ' + selectors.container),
      taglines: document.querySelectorAll(selectors.tagline),
    }

    for (const element of elements.taglines) {
      letterRotator(element).start()
    }

    const logoAnimation = letterRotator(elements.logo)

    if (elements.contact) {
      const svg = elements.contact.querySelector('svg')

      if (!svg) return

      svg.addEventListener('load', () => setTimeout(contactAnimation, 500))
      document.fonts.onloadingdone = () => setTimeout(contactAnimation, 500)

      let contactAnimationRan = false

      function contactAnimation() {
        if (contactAnimationRan) return

        contactAnimationRan = true
        let lastScrollY = window.scrollY

        svg.style.opacity = 1

        svg.addEventListener('transitionend', () => {
          svg.style.opacity = ''
          svg.style.transition = 'transform .5s'
        }, { once: true })

        const svgOffsetTop = svg.getBoundingClientRect().bottom
        svg.style.transform = `translateY(${lastScrollY < window.scrollY ? -svgOffsetTop : 0}px)`

        document.addEventListener('scroll', () => {
          svg.style.transform = `translateY(${lastScrollY < window.scrollY ? -svgOffsetTop : 0}px)`
          lastScrollY = Math.max(0, window.scrollY)
        })
      }
    }

    const trigger = new ScrollTrigger({
      trigger: {
        toggle: {
          class: {
            in: 'animateIn',
            out: 'animateOut'
          }
        }
      },
      scroll: {
        start: logoAnimation.start,
        stop: logoAnimation.stop
      }
    })

    trigger.add(selectors.teaser, {
      once: false,
      offset: {
        viewport: {
          x: 0,
          y: trigger => trigger.visible ? 0 : 0.2
        }
      }
    })

    trigger.add(selectors.sections.map(section => `${section} ${selectors.container}`).join(','), {
      once: true
    })


    /**
     * Add an increasing delay to each container, starting from
     * the first visible container in viewport
     */
    let index = 0
    for (const container of elements.containers) {
      const offsetTop = offsetRect(container).bottom

      if (offsetTop >= window.scrollY) {
        container.style.animationDelay = 0.25 + (0.45 * index) + 's'
        index++
      }
    }
  }

  function letterRotator(context = document) {
    let loaded = false, running = false, groups = [];

    function start() {
      if (loaded) {
        running = true
        loop()
      } else {
        context.addEventListener('load', () => {
          loaded = true
          running = true

          while (!groups.length) {
            groups = selectors.svgGroups.map(selector => {
              return context.contentDocument.querySelectorAll(`${selector} *`)
            })
          }

          loop()
        })
      }
    }

    function loop() {
      groups.forEach(group => {
        (function animate() {
          if (!running) return

          const curr = group.item(parseInt(Math.random() * group.length))

          for (let index = 0; index < group.length; index++) {
            const elem = group.item(index);

            elem.style.display = elem === curr ? '' : 'none'
          }

          setTimeout(animate, randomIntFromInterval(200, 1200));
        })()
      })

    }

    function stop() {
      running = false
    }

    return {
      start,
      stop
    }
  }

  return {
    init,
    letterRotator,
    onScroll: () => {
      contactAnimation.onScroll()
    }
  }
}()

export default Animations
