export function throttle(func, waitTime) {
	let timeout = null
	let previous = 0

	const later = () => {
		previous = Date.now()
		timeout = null
		func()
	}

	return function() {
		const now = Date.now()
		const remaining = waitTime - (now - previous)
		if (remaining <= 0 || remaining > waitTime) {
			if (timeout) clearTimeout(timeout)
			later()
		} else if (!timeout) {
			timeout = setTimeout(later, remaining)
		}
	}
}

export function elementsFromPoint(x, y) {
  if (!document.elementsFromPoint) {
    return [...document.msElementsFromPoint(y, y)]
  } else {
    return document.elementsFromPoint(x, y)
  }
}

export function offsetRect(elem) {
  if(!elem) elem = this;

  const width = elem.offsetWidth
  const height = elem.offsetHeight

  let x = elem.offsetLeft;
  let y = elem.offsetTop;

  while (elem = elem.offsetParent) {
    x += elem.offsetLeft;
    y += elem.offsetTop;
  }

  return {
    left: x,
    top: y,
    right: x + width,
    bottom: y + height,
    x,
    y,
    width,
    height
  };
}

export function relativeValueToAbsolute(value) {
  const [input, number, unit] = value.match(/((?:-)?[0-9]+)(.*)/)

  switch (unit) {
    case 'vw': return window.outerWidth / 100 * parseFloat(number)
    case 'vh': return window.outerHeight / 100 * parseFloat(number)
    case 'px': return parseFloat(number)
    default:
      console.log(`${unit} is not a supported unit for 'relativeValueToAbsolute'`)
      return false
  }
}

export const IE11 = !!window.msCrypto;


export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
