import $ from 'jquery'

const Newsletter = function () {

  const selectors = {
    modal: '#newsletterModal'
  }

  let elements

  function init() {
    elements = {
      modal: document.querySelector(selectors.modal),
    }

    const loc = window.location

    if (elements.modal && loc.href.indexOf(selectors.modal) != -1) {
      $(elements.modal).modal('show');

      $(elements.modal).one('hide.bs.modal', () => {
        history.pushState('', '', loc.pathname + loc.search);
      })
    }

    $('.newsletter-popup-form').on('submit', (event) => {
      submitForm(event);
    });
  }

  function submitForm(event) {
    event.preventDefault();

    dismissAllMessages();

    let error = false;

    // check for csrf token
    if(!$('.newsletter-csrf-token').val()) {
      error = true;
    }

    // check for email
    if(!$('.newsletter-popup-input').val()) {
      $('.newsletter-popup-input').addClass('error');
      error = true;
    }

    /*if(!$('.newsletter-popup-privacy-checkbox').prop('checked')) {
      $('.newsletter-popup-privacy-label').addClass('error');
      error = true;
    }*/

    if(error) return;

    const address = $('.newsletter-popup-input').val();
    const csrfToken = $('.newsletter-csrf-token').val();

    sendAddress(address, csrfToken);
  }

  function sendAddress(address, csrfToken) {
    lockForm();
    let endpoint = constructAPIEndpoint(address, csrfToken);
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {

      if(xhr.status >= 200 && xhr.status < 300) {

        let responseJson = JSON.parse(xhr.response);
        unlockForm();

        if(responseJson.success) {
          showSuccess();
        } else {
          switch(responseJson.error) {
            case 'existing':
              showErrorExists();
              break;

            default:
              showErrorGeneric();
              break;
          }
        }
      } else {
        unlockForm();
        showErrorGeneric();
      }
    };

    xhr.open('GET', endpoint);
    xhr.send();
  }

  function determineLanguageSlug() {
    return document.querySelector('html').getAttribute('lang');
  }

  function constructAPIEndpoint(address, csrfToken) {
    return [
      '',
      determineLanguageSlug(),
      'newsletter/subscribe',
      encodeURIComponent(address),
      encodeURIComponent(csrfToken),
    ].join('/').concat('.html');
  }

  function dismissAllMessages() {
    $('.newsletter-popup-message').removeClass('appear');
  }

  function showErrorGeneric() {
    $('.newsletter-popup-message--error').addClass('appear');
  }

  function showErrorExists() {
    $('.newsletter-popup-message--used').addClass('appear');
  }

  function showSuccess() {
    $('.newsletter-popup-message--success').addClass('appear');
    lockForm();
    $('.newsletter-popup-form').addClass('hide');
  }

  function resetErrors() {
    $('.newsletter-popup-wrapper .error').removeClass('error');
  }

  function lockForm() {
    $('.newsletter-popup-privacy-checkbox').prop('readonly', true);
    $('.newsletter-popup-input').prop('readonly', true);
    $('.newsletter-popup-submit').prop('readonly', true);
    $('.newsletter-popup-form').addClass('locked');
  }

  function unlockForm() {
    $('.newsletter-popup-privacy-checkbox').prop('readonly', false);
    $('.newsletter-popup-input').prop('readonly', false);
    $('.newsletter-popup-submit').prop('readonly', false);
    $('.newsletter-popup-form').removeClass('locked');
    $('.newsletter-popup-form').removeClass('hide');
  }

  function resetAll() {
    resetErrors();
    unlockForm();
    dismissAllMessages();
    $('.newsletter-popup-input').val('');
    $('.newsletter-popup-privacy-checkbox').prop('checked', false);
  }

  return {
    init,
  }
}()

export default Newsletter
