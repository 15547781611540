const Rotate = function () {
  const selector = '[data-rotate]'

  let elements = []
  let instances = []

  function init() {
    elements = [...document.querySelectorAll(selector)]

    if (!elements.length) return

    elements.forEach(element => {
      const words = JSON.parse(element.dataset.rotate)
      const instance = new TextRotator(element, words)
      instances.push(instance)
    })

    start()
  }

  function start() {
    instances.forEach(instance => instance.start())
  }

  function stop() {
    instances.forEach(instance => instance.stop())
  }

  return {
    init,
    start,
    stop
  }
}()

export default Rotate

function TextRotator(target, words, changeInterval = 2000, updateInterval = 80, waitInterval = 0) {
  let index = 0, interval = undefined, timeout = undefined

  // Parse settings from "rotate" data attribute
  const settings = JSON.parse(target.dataset.rotate)

  // If parsed settings is an array, use it as the words array
  if (Array.isArray(settings)) {
    words = settings
  } else {
    words = settings.words || words
    changeInterval = settings.changeInterval || changeInterval
    updateInterval = settings.updateInterval || updateInterval
    waitInterval = settings.waitInterval || waitInterval
  }

  const lastIndeices = [index]

  function getRandomIndex() {
    const randomIndex = Math.floor(Math.random() * words.length)
    if (lastIndeices.includes(randomIndex)) {
      return getRandomIndex()
    }
    lastIndeices.push(randomIndex)
    if (lastIndeices.length > words.length / 2) {
      lastIndeices.shift()
    }
    return randomIndex
  }

  function start(wait = 0) {
    if (wait) {
      setTimeout(start, wait)
      return
    }

    let word = words[index]
    let i = 0
    interval = setInterval(() => {
      let str = target.textContent

      while (str.length < word.length) str += ' '

      const ltr = word[i] || ' '
      const out = (str.slice(0,i) + ltr + str.slice(i+1)).trim()

      target.textContent = out

      if (++i === Math.max(word.length, str.length)) {
        clearInterval(interval)
        index = getRandomIndex()
        timeout = setTimeout(start, changeInterval)
      }
    }, updateInterval)
  }

  function stop() {
    clearInterval(interval)
    clearTimeout(timeout)
  }

  return { start: start.bind(null, waitInterval), stop }
}
