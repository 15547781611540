import { relativeValueToAbsolute } from './helpers'

const Parallax = function () {
  const selector = '[data-parallax]'

  let elements = []

  function init() { 
    elements = [...document.querySelectorAll(selector)]

    onScroll()
  }
  
  function onScroll() {
    if (!elements.length) return

    elements.forEach(element => {
      const value = window.pageYOffset - element.offsetTop + (element.offsetWidth / 2)
      const offset = relativeValueToAbsolute(element.dataset.parallaxOffset) || 0
      
      element.style.transform = `translateX(${Math.round(offset - value / 4)}px)`
    })
  }
  
  return {
    init,
    onScroll
  }
}()

export default Parallax
